<template>
  <v-app>
    <v-card class="bgs rounded-0 d-flex justify-center" flat  
      width="100%"
      height="100%"
    >
      <v-container>
        <h2 class="text-center mt-6 font24 mb-4">
          {{ $t("Taocarts.shop用户隐私政策") }}
        </h2>
        <div class="content mt-4">
          {{
            $t(
              "Taocarts.shop(以下简称“我们”)非常重视对用户个人隐私和个人信息安全的保护。我们将根据现行法律和政策采取相应的管理和安全保护措施，并制定本隐私政策(以下简称“本政策”)，同时提醒您:"
            )
          }}
        </div>
        <div class="content">
          {{
            $t(
              "本隐私政策适用干Taocarts.shop提供的产品或服务，包括您访问Taocarts.shop网站和/或登录相关应用程序时Taocarts.shop提供的服务"
            )
          }}
        </div>
        <div class="content">
          {{
            $t(
              "请注意，本政策不适用于其他第三方提供给您的服务，此类服务适用第三方声明的隐私政策。"
            )
          }}
        </div>
        <div class="content">
          {{
            $t(
              "在您使用Taocarts.shop产品和/或服务之前，请阅读并确认您已完全理解本政策。使用由Taocarts.shop提供的产品和/或服务即表明您已完全理解并同意本政策。如果您有任何问题，意见或建议，请发送电子邮件至:97668216@qq.com或致电我们的客服。"
            )
          }}
        </div>
        <div class="titlee">
          {{ $t("一、隐私政策") }}
        </div>
        <div class="titletwo">
          1.
          {{ $t("本部分将带您了解:") }}
        </div>
        <ul>
          <div class="content">
            (1) {{ $t("Taocarts.shop何时会收集您的个人信息(第2节);") }}
          </div>
          <div class="content">
            (2) {{ $t("Taocarts.shop收集哪些个人信息(第3-6节);") }}
          </div>
          <div class="content">
            (3)
            {{
              $t(
                "Taocarts.shop为何使用您的个人信息，以及如何使用和保护您的个人信息(第7-12节);"
              )
            }}
          </div>
          <div class="content">
            (4)
            {{ $t("撤回/更正个人信息或处理与隐私政策有关的问题(第13-15节);") }}
          </div>
        </ul>
        <div class="titletwo">
          2.
          {{ $t("Taocarts.shop何时会收集您的个人信息?") }}
        </div>
        <div class="content">
          2.1 {{ $t("我们将/可能在以下情况下收集您的个人信息") }}:
        </div>

        <ul>
          <li>
            a)
            {{
              $t("当您注册为卖方和/或使用本服务或平台，或在平台上开立账户时；")
            }}
          </li>
          <li>
            b)
            {{
              $t(
                "当您在线或以书面形式提交表格时，包括但不限于申请表或与我们的产品或服务相关的其他表格；"
              )
            }}
          </li>
          <li>
            c)
            {{
              $t(
                "当您为与我们互动而签署协议或提供其他文件或信息时，或当您使用我们的产品和服务时；"
              )
            }}
          </li>
          <li>
            d)
            {{
              $t(
                "当您通过电话(可被录音)即时通讯应用程序或软件、信件、传真、电子邮件、面对面会议、交媒体平台等方式与我们沟通时；"
              )
            }}
          </li>
          <li>
            e) {{ $t("当您在我们的论坛提出问题或回答其他用户的问题时；") }}
          </li>
          <li>
            f)
            {{
              $t(
                "当您使用我们的电子服务，或通过我们网站上的应用程序或服务与我们沟通时，包括但不限于通过cookies(当您接触我们的应用程序或网站时，我们可能会设置)进行收集;"
              )
            }}
          </li>
          <li>g) {{ $t("当您通过我们的服务进行交易时") }}</li>
          <li>h) {{ $t("当您向我们提供意见或投诉时") }}</li>
          <li>i) {{ $t("当您报名参加意赛和其他营销活动时") }}</li>
          <li>
            j)
            {{
              $t(
                "当您因任何原因向我们提交个人信息时，以上只是我们可能会收集您的个人信息的一部分情况"
              )
            }}
          </li>
        </ul>
        <div class="titletwo">
          3.
          {{ $t("Taocarts.shop收集哪些个人信息?") }}
        </div>
        <div class="content">
          3.1 {{ $t("Taocarts.shop收集的个人信息包括但不限于") }}:
        </div>
        <ul>
          <li>
            a)
            {{
              $t(
                "基本信息(包括您的姓名，年龄，生日，性别，国籍、地址，账单地址，电话号码，电子邮箱地址等)"
              )
            }}
          </li>
          <li>
            b)
            {{
              $t("身份信息(包括身份证，工作证、护照、驾驶证，其他身份证件等);")
            }}
          </li>

          <li>c) {{ $t("面部特征;") }}</li>
          <li>
            d)
            {{
              $t(
                "网络身份信息(包括与上述内容相关的系统帐号，IP地址，电子邮件地址和密码):"
              )
            }}
          </li>
          <li>
            e)
            {{
              $t(
                "财务信息(交易、银行账户及积分、优惠券，Taocarts.shop账户余额等):"
              )
            }}
          </li>
          <li>
            f)
            {{
              $t(
                "上网记录(包括计算机IP地址、操作系统，网站浏览记录、软件使用记录、点击记录等);设备信息(包括硬件型号、设备MAC地址，操作系统类型、软件列表、设备唯一标识符(如:IME/android ID/IDFA/OPENUDID/GUID)、SIM卡的IMSI等);位置信息(包括出行信息、准确定位信息、住宿信息、经纬度等):"
              )
            }}
          </li>
          <li>g) {{ $t("由第三方服务提供商开立的账户信息；") }}</li>
          <li>
            h)
            {{
              $t(
                "用户在注册使用我们的服务或网站时，以及在使用我们的服务或网站过程中提供的任何其他信息;"
              )
            }}
          </li>
          <li>i) {{ $t("可以提供用户交互总结的信息。") }}</li>
        </ul>
        <div class="content mt-1">
          3.2
          {{
            $t(
              "您承诺不向我们提交任何不正确或误导性的信息，并同意将该等信息的何不准确内容或事改内容告知我们。Taocarts.shop保留单方面要求提供进一步文件以核实您所提供的信息的权利。"
            )
          }}:
        </div>
        <div class="content mt-1">
          3.3
          {{
            $t(
              "如果您不希望我们收集上述信息/个人信息，您可随时以书面形式通知个人信息安全专员，并选择不收集其些信息，有关选择不收焦些信息的更多详情，请参阅下文“如何撤销同意，删除请求访问或更正您所提供的信息?”一节，但请注意，选择不收集某些信息或撤销授权我们收集，使用或处理您的个人信息将影响您对我们服务和平台的使用。例如，如果您选择不收集位置信息，基于位置的功能将被禁用。"
            )
          }}:
        </div>
        <div class="titletwo">
          4.
          {{ $t("其他信息的收集") }}
        </div>
        <div class="content mt-1">
          4.1
          {{
            $t(
              "与大多数网站和移动应用程序一样，您的设备发送的信息可句括您浏览我们平台时weh服务器记录的有关您的数据，我们收集的信息可能包括但不限于:您的互联网通信协议(IP)地址，计算机/移动设备操作系统和浏览器类型，移动设备类型，移动设备特征，移动设备唯一设备标识符(UDID)或移动设备标识符(MEID)，参考网站地址(如有)，以及您在我们的网站和移动应用程序上访回的页面和访问时间，有时还包括帮助网站记录您的最后一次访问时间的“cookie”(可被浏览	器禁用)。如果您已经登录，这些信息将与您的账户相关联。这些信息也包含在匿名统计中以帮助我们了解访问者如何使用我们的网站。"
            )
          }}:
        </div>
        <div class="content mt-1">
          4.2
          {{
            $t(
              "我们的移动应用程序可的会使用GPS Wi-Fi和其他技术来收集关于您移动设备位置的准确信息，我们可能会出于一项或多项目的收售 使用披露和/或处理上述数据，这些目的包括但不限于:基于位置的服务请求，根据您的位置向您发送相关内容，以及允许您通过我们移动应用程序中的服务与其他用户共享您的位置，对于大多数移动设备，您可通过设备来禁止我们访问您的位害信息，如果您对如何禁用移动设备定位服务有任何疑问，请与您的设备服务提供商或制造商联系。"
            )
          }}:
        </div>

        <div class="titletwo">
          5.
          {{ $t("COOKIES") }}
        </div>
        <div class="content mt-1">5.1 {{ $t("什么是Cookies?") }}:</div>
        <div class="content mt-1">
          {{
            $t(
              "Cookies是我们发送到您的计算机或移动设备的标识符。“Cookies”可让网站记住您的操作和偏好设置(例如登录信息和区域选择)，从而改进您的浏览体验。这意味着您无需在每次返回网站时或在从一个页面浏览到另一个页面时重新输入此类信息。同时，“Cookies”允许我们识别您的计复机或设备，告诉我们服务或网站如何以及何时被使用或访问，访问者的数量，并跟踪我们网站上的活动。"
            )
          }}
        </div>
        <div class="content mt-1">5.2 {{ $t("我们为什么使用Cookies?") }}</div>
        <div class="content mt-1">
          {{
            $t(
              "我们因如下原因使用Cookies:由于技术上的原因，需要一些Cookies来运行我们的网站，我们将这些称为基本的网站Cookies。第三方通过我们的网站提供Cookies，用于网站数据分析和其他目的，我们将这些称为系统性分析Cookies."
            )
          }}
        </div>
        <div class="content mt-1">
          {{
            $t(
              "下表描述了通过我们的网站提供的第一方和第三方Cookies的具体类型及其执行目的:"
            )
          }}
        </div>

        <v-row class="ma-0 my-3">
          <v-col cols="12" sm="5" class="pa-0" style="border: 1px solid #ddd">
            <div class="tab content">{{ $t("Cookie的种类") }}</div>
            <div class="tab">
              <p class="mb-0 content">{{ $t("基础的网站Cookies") }}：</p>
              <p class="mb-0 content">
                {{
                  $t(
                    "这些Cookies收集的信息，在为您提供我们网站的服务并使用其中的一些功能时使用，例如我们需要知道您当前的登录状态以判断是否为会员。"
                  )
                }}
              </p>
            </div>
            <div class="tab" style="border-bottom: none">
              <p class="mb-0 content">{{ $t("系统性分析Cookies") }}：</p>
              <p class="mb-0 content">
                {{
                  $t(
                    "这些Cookies收集的信息，以多种形式帮助我们了解如何使用我们的网站访问情况如何，或帮助我们为您定制我们的网站。"
                  )
                }}
              </p>
            </div>
          </v-col>
          <v-col cols="6" sm="5" class="pa-0" style="border: 1px solid #ddd">
            <div class="tab content">{{ $t("功能") }}</div>
            <div class="tab content" style="height: 69.5px">
              {{ $t("用户登录") }}
            </div>
            <div class="tab content" style="height: 69.5px">
              {{ $t("通知公告") }}
            </div>
            <div class="tab" style="border-bottom: none">
              <p class="mb-0 content">{{ $t("数据分析网站") }}：</p>
              <p class="mb-0 content">
                Google Tag Manager、Facebook pixel、Reddit Pixel
              </p>
            </div>
          </v-col>
          <v-col cols="6" sm="2" class="pa-0" style="border: 1px solid #ddd">
            <div class="tab content">{{ $t("期限") }}</div>
            <div class="tab content" style="height: 69.5px">
              {{ $t("永久") }}
            </div>
            <div class="tab content" style="height: 69.5px">
              {{ $t("永久") }}
            </div>
            <div class="tab content" style="border-bottom: none">
              {{ $t("永久") }}
            </div>
          </v-col>
        </v-row>
        <div class="content mt-1">5.3 {{ $t("如何控制Cookies") }}</div>
        <div class="content mt-1">
          {{
            $t(
              "您可以设置或修改您的网页浏览器控件，以接受或拒绝Cookies，如果您选择拒绝Cookies，您仍然可以使用我们的网站，此时您在访问我们网站的一些功能和区域时可能会受限，您拒绝使用Cookies的方式会因浏览器的不同而不同，您可以通讨点击下面主流浏资器的链接来设置您的 Cookies偏好。"
            )
          }}
        </div>
        <ul>
          <li>
            a) Chrome:https://support.google.com/chrome/answer/95647?hl=zh-Hans
          </li>
          <li>b) Firefox:https://supportmozilla.orq/zh-CN/kb/Cookies</li>
          <li>
            c) InternetExplorer:https://supportmicrosoft.com/zh-cn/windows/%E5%88%A0%E9%99%A4%E5%92%8C%E7%AE%A1%E7%90%86-cookie-168dab11-0753-043d-7c16-ede5947fc64d
          </li>
          <li>
            d) Safari:https://support.apple.com/zh-cn/guide/safari/sfr11471/mac
          </li>
        </ul>
        <div class="titletwo">
          6.
          {{ $t("浏览和下载内容和广告") }}
        </div>
        <div class="content mt-1">
          6.1
          {{
            $t(
              "当您在我们的网站或通讨我们的服务浏览内容，广告和访问其他软件时，系统会向我们发送信息，这些信息与您浏览我们的网页或移动应用释放时发送的信息基本相同(包括但不限于IP地址，操作系统等);但与网页浏览不同的是，您的设备将向我们传输与您浏览的内容和广告相关的信息，和/或与我们的服务、安装在我们网站上的软件和访问时间相关的信息。"
            )
          }}
        </div>
        <div class="titletwo">
          7.
          {{ $t("Taocarts.shop如何使用您提供的信息?") }}
        </div>
        <div class="content mt-1">
          7.1
          {{
            $t(
              "我们可能出于以下一项或多项目的收集，使用，披露和/或处理您的个人信息:"
            )
          }}
        </div>
        <ul>
          <li>
            a)
            {{
              $t(
                "考虑和/或处理您与我们的申请/交易，或您通过本服务与第三方的交易或通信:"
              )
            }}
          </li>
          <li>
            b)
            {{
              $t(
                "管理、运营、提供和/或处理您对本服务和平台的使用和/或访问(包括但不限于记录您的偏好)，以及您与我们的关系和您开立的用户账户;"
              )
            }}
          </li>
          <li>
            c)
            {{
              $t(
                "回应，处理或完成交易和/或满足您对特定产品和服务的要求，以及向您告知服务问题和账户操作异常:"
              )
            }}
          </li>
          <li>
            d)
            {{ $t("在各区域执行我们的服务条款或任何适用的终端用户许可协议;") }}
          </li>
          <li>e) {{ $t("保护人身安全、权利、财产安全或他人的安全;") }}</li>
          <li>f) {{ $t("识别和/或验证;") }}</li>
          <li>
            g)
            {{
              $t(
                "维护和管理不定期的软件更新和/或为确保本服务顺利运作所需的其他更新和支持；"
              )
            }}
          </li>
          <li>
            h)
            {{
              $t(
                "处理或促进客户服务，执行您的指示，处理或回应由您或代表您(或声称由您或代表您)提出的所有询问；"
              )
            }}
          </li>
          <li>
            i)
            {{
              $t(
                "为执行和/或管理您与我们的关系或您对本服务的使用，通过电话，短信和或传电子邮件和/或邮件或其他方式与您联系或沟通，但不限于向您传达与本服务有关的管理信息。您确认并同意，我们可能会通过邮寄信件、文件和通知与您沟通，在投递过程中，信封/邮件包裹外面可能会披露您的具体个人信息;"
              )
            }}
          </li>
          <li>
            j)
            {{
              $t(
                "允许其他用户在平台上与您互动或联系，包括当用户向您发送短信或在平台上发表关于您的评论时向您发出通知;"
              )
            }}
          </li>
          <li>
            k)
            {{
              $t(
                "开展研究分析和开发活动(包括但不限于数据分析问卷调查产品和服务开发和/或分析)，以分析您对我们服务的使用，改善我们的服务或产品和/或提高您的客户体验;"
              )
            }}
          </li>
          <li>
            l)
            {{
              $t(
                "允许审计和调查，以确定目标受众的规模和组成，并了解他们使用Taocarts.shop服务的体验;"
              )
            }}
          </li>
          <li>
            m)
            {{
              $t(
                "出于营销和相关目的，向您发送Taocarts.shop(和/或其关联企业或关联公司)可能通过各种沟通方式销售;营销或推广的产品和/或服务(包括	但不限于可能与Taocarts.shop合作的第三方的产品和/或服务，或第三方与Taocarts.shop共同提供的产品和/或服务)。您可以通过电子营销资料中的退订功随时取消订阅接收营销信息，我们可能会通过您的联系信息向您发送关于我们和我们关联公司的消息:"
              )
            }}
          </li>
          <li>
            n)
            {{
              $t(
                "对法律程序作出反应或遭守相关司法管辖区的任何适用法律、政府要求或监管要求，包括但不限于道守任何法律规定下的、对Taocarts.shop或其关联公司或关联企业具有约束力的披露要求:"
              )
            }}
          </li>
          <li>
            o)
            {{
              $t(
                "生成统计数据或进行研究，以满足内部及法定报告需要和/或记录保存:"
              )
            }}
          </li>
          <li>
            p)
            {{
              $t(
                "根据法律或监管义务，或根据法律要求或我们制定的风险管理程序，讲行尽职调查或其他审查活动(包括但不限于背导调查)"
              )
            }}
          </li>
          <li>q) {{ $t("审计本服务或Taocarts.shop的业务;") }}</li>
          <li>
            r)
            {{
              $t(
                "防止或调查任何确实或导似的Taocarts.shop政策违反行为，欺诈行为，非法活动，疏忽或不当行为，无论这些行为是否与您使用本服务有关或因您与我们的关系而产生:"
              )
            }}
          </li>
          <li>
            s)
            {{
              $t(
                "在您的管辖范围内或范围外存储，托管和备份您的个人信息(无论是用于灾后数据恢复还是其他目的)。"
              )
            }}
          </li>
          <li>
            t)
            {{
              $t(
                "处理和/促进业务资产的交易或可能的企业资产交易，其中的参与者包括Taocarts.shop，或仅包括Taocarts.shop的关联公司或关联企业，或Taocarts.shop和/或Taocarts.shop的任何或多家关联公司或关联企业，可能还有其他第三方组织参与交易。“业务资产交易”是指对组织或组织的任何部分的任何业务或资产的收购，出售、租赁，整台、合并或任何其他收购，处置或融资;"
              )
            }}
          </li>
          <li>
            u)
            {{
              $t(
                "将信息用干本政策未明确规定的目的，以及我们在获取您同意时已告知您的任何其他目的。(合称为“目的”)"
              )
            }}
          </li>
        </ul>
        <div class="content mt-1">
          7.2
          {{
            $t(
              "由于我们将/可能收集，使用，披露或处理您的个人信息的目的取决干实时发生的情况，所以该等目的可能未被包含在上述内容中。但是，除非相关法律允许我们在未经您同意的情况下处理适用信息，否则我们将在获取您的同意时向您告知该等目的。"
            )
          }}:
        </div>
        <div class="titletwo">
          8.
          {{ $t("Taocarts.shop如何保护您的个人信息?") }}
        </div>
        <div class="content mt-1">
          8.1
          {{
            $t(
              "我们将在现有技术允许的情况下采取各种合理和必要的安全措施(包括但不限于物理，技术和行政措施)，以确保您的个人信息在我们的系统中是安全可靠的，从而最大限度地降低丢失，误用、未经授权访问，披露和变更的风险，由于各种恶意手段和科学技术的不断突破，您理解并同意，即使我们尽最大努力采取上述措施，您的个人信息仍有可能被泄露，捐坏或丢失。Taocarts.shop不做与此相关的任何承诺或保证。"
            )
          }}:
        </div>
        <div class="content mt-1">
          8.2
          {{
            $t(
              "我们不向未成年人推荐我们的服务，除非当地法律允许并经监护人同意，否则不允许未成年人注册账户或将其姓名、地址、电话号码和电子邮件地址等个人信息发送给我们。如果我们意外收集了关于未成年人的信息，我们会在知悉后立即删除，如果您认为我们可能不恰当地持有来自或关于未成年人的信息，请与我们联系。"
            )
          }}:
        </div>
        <div class="content mt-1">
          8.3
          {{
            $t(
              "我们将按照有关隐私保护的法律法规和/或其他适用法律的规定保留您的个人信息，即如果我们有理由相信发生以下情况，则我们将销照您的个人信息或将其要名化外理:(1)该等个人信息的保留已不再符合收集个人信息的目的:(2)不再要要出于任何法律或业务目的而保留个人信息:以及(3)该等个人信息的进一步保留不受任何其他合法权益的保证。如果您停止使用我们的平台或您使用平台和/或服务的权利被终止，我们将按照适用法律的要求，根据本隐私政策和相关院私法规定的义务继续处理您的个人信息。"
            )
          }}:
        </div>
        <div class="titletwo">
          9.
          {{ $t("Taocarts.shop会向第三方披露其从访问者处收集的信息吗?") }}
        </div>
        <div class="content mt-1">
          9.1
          {{
            $t(
              "在开展业务的过程中，我们将/可能需要出于一项或多项目的，将处理后的您的个人信息披露给第三方服务提供商、代理商和/或我们的关联公司或关联企业和/或其他第三方(无论是否位千中国)，该等第三方服务提供商，代理商和/或关联公司或关联企业和/或其他第三方可代表我们或以其他方式出于上述一项或多项目的处理您的个人信息。第三方包括但不限于"
            )
          }}:
        </div>
        <ul>
          <li>a) {{ $t("我们的子公司，关联公司和关联企业:") }}</li>
          <li>b) {{ $t("出于上述一项或多项目的而使用我们网站的用户") }}</li>
          <li>
            c)
            {{
              $t(
                "我们用于支持我们业务的承包商，代理商，服务提供商和其他第三方服务提供商“授权合作伙伴”)，包括但不限于向我们提供管理或其他服务的组织，如:邮寄公司，支付服务提供商，电信公司。信息技术公司和数据中心:我们仅会出于本隐私政策的合法，合理、必要，具体和明确的目的而共享您的信息，授权合作伙伴只能访问其履行职责所需的信息，目不得将此类信息用千任何其他目的。"
              )
            }}
          </li>
          <li>
            d)
            {{
              $t(
                "合并、剥离、改组、重组、解散或以其他方式出售或转让(无论是以持续经营的形式，还是作为破产、清算或类似程序的一部分)Taocarts.shop	的部分或全部资产情况下的接收方，或Taocarts.shop的任何其他继承者，其中，Taocarts.shop持有的，有关服务卖方的个人信息也在转计资产中;或 Taocarts.shop或其任何关联公司或关联企业参与的业务资产交易的对手方:"
              )
            }}
          </li>
          <li>
            e)
            {{
              $t(
                "我们出于一项或多项目的向其披露个人信息的第三方，而该第三方又可能出于一项或多项目的收集和处理您的个人信息。"
              )
            }}
          </li>
        </ul>
        <div class="content mt-1">
          9.2
          {{
            $t(
              "除其他事项外，可能需要与广告和项目提供商共享的信息还包括用户数量及其服务使用情况的统计数据。此信息不包括任何可用于识别或找到您的个人信息。"
            )
          }}
        </div>
        <div class="content mt-1">
          9.3
          {{
            $t(
              "为免生疑问特此说明，如果隐私法律法规允许公司(即Taocarts.shop)在未经您同意的情况下收集、使用或披露您的个人信息，则本授权应继续适用。"
            )
          }}
        </div>
        <div class="content mt-1">
          9.4
          {{
            $t(
              "第三方可能会在非我方过错的情况下，非法拦截或访问传输到我们网站的个人信息或其中包含的个人信息，技术可能出现故障或无法按预期运行，或其他人可能访问，滥用或误用个人信息，我们将根据障私法的相关规定，采取合理的安全措施保护您的个人信息，但我们无法保证绝对的安全，包括但不限于并非由我们的过错，而是由恶意而老练的黑客造成的未经授权的披露。"
            )
          }}
        </div>
        <div class="titletwo">
          10.
          {{ $t("第三方收集的信息") }}
        </div>
        <div class="content mt-1">
          10.1
          {{
            $t(
              "为了提供更好的客户服务和用户体验，平台使用Google.Inc(以下简称”Google”)提供的网页分析服务-Google Analytics，例如，Google Analytics使用cookies(您计算机上的文本文件)来帮助网站分析用户对平台的使用情况，由cookies生成的有关您网站使用情况的信息(包括您的IP地址)被发送并存储在Google的服务器上。Google将使用这些信息评估您的网站使用情况，为网站运营商编写活动报告，并提供与网站活动和互联网使用相关的其他服务，Google也可能被法律要求将这些信息传输给代表Google外理这些信息的第三方，Google不会将您的ip地址与 Google持有的其他数据关联起来。"
            )
          }}
        </div>
        <div class="content mt-1">
          10.2
          {{
            $t(
              "我们和第三方可能会在本服务中或通过本服务不时下载软件应用程序供您使用，这些应用程序可能单独访问并允许第三方查看您的身份信息(即您的姓名，用户ID，计算机地址或其他信息)，例如您以前安装的，或由第三方软件应用程序或网站为您安装的cookies。此外，这些应用程序可能要求您直接向第三方提供额外的信息。Taocarts.shop并未持有或控制通过这些应用程序提供的第三方产品或服务。我们建议您查看这些第三方在其网站或其他地方发布的条款和其他政策。"
            )
          }}
        </div>
        <div class="titletwo">
          11.
          {{ $t("有关安全和第三方网站的免责声明") }}
        </div>
        <div class="content mt-1">
          11.1
          {{
            $t(
              "我们不保证您在第三方网站上提供的个人信息和/或其他信息的安全。为了维护我们持有或控制的、有关您的个人信息的安全，我们确实采取了各种安全措施。您的个人信息存储在受保护的网络中，仅供对网络具有特殊访问权限并被要求对此类个人信息保密的有限人员访问，当您使用本服务或向平台提交个人信息时，我们将提供一个安全服务器。您提供的所有个人信息将以加密方式存储在我们的数据库中，并只可按上述条款规定的方式访问。"
            )
          }}
        </div>
        <div class="content mt-1">
          11.2
          {{
            $t(
              "为了提供更高价值的服务，我们可选择将各类第三方网站链接至平台，并将第三方网站置于平台框架内。我们也可参与联合品牌活动或建立类似的关系，为访问者提供电子商务和其他服务和功能，此类链接网站有独立的隐私政策和安全措施。即使第三方与我们有关联，我们对此类链接网站也不具有管控权，而每个链接网站都有独立的隐私权和信息收集惯例，不受我们约束。我们不收集我们的合作品牌或第三方网站收集的信息(即便是在我们的网站上提供或通过我们的双站提供)。"
            )
          }}
        </div>
        <div class="content mt-1">
          11.3
          {{
            $t(
              "因此，我们不对此类链接网站的内容，安全措施(或缺多此类安全措施)和活动负责。此类链接网站仅为方便使用而提供，因此应自行承担风险。尽管如此，我们仍然努力保护平台及其中能接的完整性。因此，我们非常欢迎您就此类链接网站向我们提供您的意见(包括但不限于特定链接无法正常使用的情况)。"
            )
          }}
        </div>

        <div class="titletwo">
          12.
          {{ $t("Taocarts.shop会把您的个人信息传输到国外吗?") }}
        </div>
        <div class="content mt-1">
          12.1
          {{
            $t(
              "您的个人数据和/或信息可能会被传输到您所在国家/地区以外的地方存储或处理。在大多数情况下，您的个人信息是在美国处理的，因为我们的服务器位于美国，我们在这里运行我们的中央数据库。Taocarts.shop只会在符台相关隐私法律法规的情况下将您的信息传输到国外。"
            )
          }}
        </div>
        <div class="titletwo">
          13.
          {{ $t("如何撤销同意，删除，请求访问或更正您所提供的信息?") }}
        </div>

        <div class="content mt-1">
          13.1 {{ $t("选择不收集些信息以及撒销同意") }}
        </div>
        <div class="content mt-1">
          13.1.1
          {{
            $t(
              "您可通过发送电子邮件至97668216@qq.com联系个人信息安全专员，选择不收集其些信息，并不再同意我们收集，使用和/或披露我们持有或控制的您的个人信息，我们将按照本隐私政策以及隐私法和其他活用法律下的相关义务行事，然而，通过选择不收集基些信息/撤销您的同意，即代表您同意，我们将无法继续向您提供我们的服务，并可能要求您终止您与我们之间的现有关系和/或有关服务的合同。"
            )
          }}
        </div>
        <div class="content mt-1">
          13.2 {{ $t("请求访问和/或更正您的个人信息。") }}
        </div>
        <div class="content mt-1">
          13.2.1
          {{
            $t(
              "如果您已向我们注册，您可以通过您的账户经理或平台的相应页面访问和/或更正我们目前持有或控制的您的个人信息。如果您没有账户，您可以通过提交书面请求，请求访问和/或更正我们目前持有或控制的个人信息。您必须得供足够的信息，以便我们确定您的身份和您的请求的性质，从而处理您的请求。请通过发送电子邮件至97668216@qq.com(“个人信息安全专员”)提交您的书面请求"
            )
          }}
        </div>

        <div class="content mt-1">
          13.2.2
          {{
            $t(
              "在某些情况下，我们会可能会向您收取合理的费用，以处理您访问个人信息的请求，如果需要收费，我们将以书面形式(包括电子邮件)向您提供项目和价格。请注意，除非您同意付费，否则我们无需回应或处理您的访问请求"
            )
          }}
        </div>
        <div class="content mt-1">
          13.2.3
          {{
            $t(
              "根据隐私法的规定，在既定情况下，企业有权在被要求和/或授权时拒绝更正您的个人信息，我们保留拒绝更正您的个人信息的权利"
            )
          }}
        </div>
        <div class="titletwo">
          14 {{ $t("如有问题，疑虑或投诉，请与我们联系") }}
        </div>
        <div class="content mt-1">
          14.1{{
            $t(
              "如果您对我们的隐私实践或与本服务相关的交易有任何问题或疑虑，请随时与我们联系"
            )
          }}:97668216@qq.com
        </div>

        <div class="titletwo">15 {{ $t("我们的隐私政策可能会变更") }}</div>
        <div class="content mt-1">
          15.1
          {{
            $t(
              "随着Taocarts.shop业务的发展，我们将更新本隐私政策，为您提供更好的服务。但是，未经您明确同意，您在本政策下的权利不会受到限制，我们将在Taocarts.shop网站上公布我们隐私政策的任何变更。"
            )
          }}
        </div>
        <div class="content mt-1">
          15.2
          {{
            $t(
              "对于大变更，我们还将以更醒目的方式通知您(我们将通过包括但不限于电子邮件，短信或在网页上显示特别提醒等方式解释隐私政策的具体变更)。"
            )
          }}
        </div>
        <div class="content mt-1">
          15.3 {{ $t("本隐私政策涉及的重大变更包括但不限于") }}
        </div>
        <ul>
          <li>
            a)
            {{
              $t(
                "我们的服务模式发生重大变化(即个人信息处理的目的，个人信息处理的类型，个人信息的使用等);"
              )
            }}
          </li>
          <li>
            b)
            {{
              $t(
                "我们的控制权发生重大变化等(如因合并、收购、重组而导致的信息控制人变更);"
              )
            }}
          </li>
          <li>c) {{ $t("个人信息共享，转让或公开披露的主体发生变更；") }}</li>
          <li>
            d) {{ $t("您参与个人信息处理的权利及其行使方式发生重大变更；") }}
          </li>
          <li>
            e) {{ $t("我们的部门、联系方式及个人信息安全处理渠道发生变更；") }}
          </li>
          <li>
            f)
            {{
              $t(
                "《个人信息安全影响评估报告》中指出的高风险。我们还将在Taocarts.shop上存档本隐私政策的旧版本，供您参考。"
              )
            }}
          </li>
        </ul>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.titlee {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  line-height: 25px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.titletwo {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  line-height: 25px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.content {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  color: #6a6e79;
}
li {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  color: #6a6e79;
  margin-left: 20px;
  list-style: initial;
}
.tab {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
</style>